import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import styled from "styled-components"

const NewsTitle = styled.div`
  @media (max-width: 768px) {
    padding: 0 5%;
  }
`
const NewsTitleSub = styled.p``
const NewsTitleMain = styled.h1`
  margin-top: 50px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 768px) {
    margin-top: 25px;
    font-size: 18px;
  }
`

const NewsDetailArea = styled.div`
  padding: 5% 10%;
  @media (max-width: 768px) {
    padding: 5% 5%;
  }
`
const NewsMainImg = styled.img`
  width: 100%;
  padding-bottom: 5%;
`
const NewsTextArea = styled.div``
const NewsSubTitle = styled.h2`
  margin: 30px 0 10px;
  font-size: 20px;
  font-weight: bold;
`
const NewsText = styled.p`
  font-size: 13px;
  color: rgb(64, 69, 79);
  line-height: 2.769;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`
const NewsSignature = styled.p`
  margin-top: 30px;
  font-size: 13px;
  color: rgb(64, 69, 79);
  line-height: 2.769;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`

const NewsRenewalPage = () => (
  <Layout sectionClasses={"t-news"} dataNamespace={"news_detail"}>
    <SEO title="2021年7月28日HOTARU PERSONALIZEDリニューアル" />

    <div className="t-news__ttl p-title">
      <span className="t-news__ttl__main">INFORMATION</span>
      <span className="t-news__ttl__sub">NEWS&TOPICS</span>
    </div>

    <div className="t-news__detail">
      <NewsTitle className="t-news__detail__ttl">
        <NewsTitleSub>
          <span>2021-07-02</span> <span>INFORMATION</span>
        </NewsTitleSub>
        <NewsTitleMain>
          2021年7月28日 HOTARU PERSONALIZEDリニューアル
        </NewsTitleMain>
      </NewsTitle>
      <NewsDetailArea>
        <NewsMainImg
          src={`https://info.hotaru-personalized.com/wp-content/uploads/2021/06/HOTARUリニューアルお知らせFV.jpg`}
        />
        <NewsTextArea>
          <NewsText>
            2021年7月28日(水)
            <br />
            「あなたのために作るパーソナライズスキンケア“HOTARU
            PERSONALIZED”がリニューアル！新しい体験と実感をお届けします。」
          </NewsText>

          <NewsSubTitle>リニューアルについて</NewsSubTitle>
          <NewsText>
            この度のリニューアルでは、10万人を超える肌質データと、ご利用者様からフィードバックいただいた内容をもとに、商品をパワーアップ。
            <br />
            毛穴や乾燥などお悩み別の処方を強化し、実感を高めました。
            <br />
            また、一人一人のお肌により深く向き合えるよう、生活習慣アドバイスなどを充実させ、あなた専用のスキンケアプランを提案いたします。
          </NewsText>

          <NewsText>
            進化するHOTARU PERSONALIZEDを、是非あなたのお肌で、ご実感ください。
          </NewsText>

          <NewsText>
            <span>
              ※LOTIONのみリニューアル後は容量が変更になります。(100ml→115ml)
            </span>
          </NewsText>

          <NewsSubTitle>お届け開始時期について</NewsSubTitle>
          <NewsText>
            2021年7月28日(水)から順次、リニューアル商品に切り替えてご注文を受付いたします。
            <br />
            現在定期コースをご契約中のお客様には、8月2日(月)出荷分から、リニューアル商品を発送いたします。
            <br />
            ※ リニューアルへ商品への切替日は、予告なく前後する場合がございます。
            <br />
          </NewsText>

          <NewsText>
            お問い合わせは、
            <br />
            HOTARU PERSONALIZEDお客様サポートまでお願いいたします。
          </NewsText>

          <NewsSignature>
            *************************************************
            <br />
            HOTARU PERSONALIZEDお客様サポート
            <br />
            電話：0570-666-886（土日祝を除く平日/09:00〜18:00)
            <br />
            メール：hotaru@shop.sparty.jp
            <br />
            *************************************************
          </NewsSignature>
        </NewsTextArea>
      </NewsDetailArea>
    </div>

    <Link class="p-pageBackBtn" to="/">
      <div class="c-grid">
        <div class="c-grid__row">
          <div class="c-grid__col">
            <span class="p-pageBackBtn__inner">BACK TO TOP</span>
          </div>
        </div>
      </div>
    </Link>
  </Layout>
)

export default NewsRenewalPage
